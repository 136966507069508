import styled from "styled-components";
import React, { useRef, useEffect, useState }  from 'react';
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import { gsap } from "gsap";
import { Accordion, AccordionItem as Item  } from '@szhsin/react-accordion';
import AnchorLink from 'react-anchor-link-smooth-scroll'


import InputRange from 'react-input-range';
import defaultClassNames from 'react-input-range/src/js/input-range/default-class-names.js';

import 'react-input-range/lib/css/index.css';

import Layout from '../components/Layout'

// Hero
import HeroThumbnail from '../img/hero-thumbnail-v2.png'
import HeroElement from '../img/hero/element.png'
import Paul from '../img/hero/buyer-2.png'
import Scott from '../img/hero/buyer-4.png'
import Japoy from '../img/hero/buyer-5.png'
import Pao from '../img/hero/owner.png'

// Element
import FirstElement from '../img/first-element.png'

// New KKB
import ExpandReach from '../img/icons/expand.svg'
import ReduceSpend from '../img/icons/reduce.svg'
import SecureOrders from '../img/icons/secure.svg'

// Features
import GroupPageElement from '../img/features/group-buy-element.png'
import PaymentCheckoutElement from '../img/features/payments-element.png'
import RulesElement from '../img/features/set-rules-element.png'


// How it works
import OwnerOne from '../img/how-it-works/owner-1.png'
import OwnerTwo from '../img/how-it-works/owner-2.png'
import OwnerThree from '../img/how-it-works/owner-3.png'

import SignUp from '../img/sign-up.svg'
import Create from '../img/create.svg'
import Cascade from '../img/cascade.svg'
import Chill from '../img/chill.svg'

// Contact
import ContactElement from '../img/contact-us-element.png'

// Logos
import Netflix from '../img/logos/netflix.png'
import DisneyPlus from '../img/logos/disney.png'
import Youtube from '../img/logos/youtube.png'
import Spotify from '../img/logos/spotify.png'
import Canva from '../img/logos/canva.png'
import Audible from '../img/logos/audible.png'

// Icons 
import { ArrowRight, Question, CaretDown } from "phosphor-react";
import "./styles.module.css";


function IndexPageTemplate() {

  const boxRef = useRef();
  const ownerMessage = useRef();

  const [subscriptionAmount, setSubscriptionAmount] = useState(500);
  const [numberOfPeople, setNumberOfPeople] = useState(2);
  
  defaultClassNames['labelContainer'] = 'is-hidden';
  defaultClassNames['slider'] = 'input-range__slider input-range__slider-custom'
  defaultClassNames['track'] = 'input-range__track input-range__track--background  input-range__track-custom'
  defaultClassNames['activeTrack'] = 'input-range__track input-range__track--active input-range__track--active-custom'

  const handleSubscriptionAmountChange = (value) => {
    setSubscriptionAmount(value);
  };

  const handleNumberOfPeopleChange = (value) => {
    setNumberOfPeople(value);
  };

  // Yearly Computation
  // const computeSavings = (subscriptionAmount * 12) / numberOfPeople
  // const monthlySavings = (subscriptionAmount * 12) - computeSavings;

  // Monthly
  const computeSavings = Math.round(subscriptionAmount / (numberOfPeople + 1));
  const monthlySavings = Math.round(subscriptionAmount - computeSavings);

  useEffect(() => {

    const HeroTimeLine = gsap.timeline({defaults: {duration: 1}});

    //no more repitition of duration: 1!
    HeroTimeLine
      .from(ownerMessage.current, {
        duration: 0.5, 
        delay: 1,
        x: -60,
        y: 40,
        ease: "back.out(2)",
        yoyo: true,
        scale: 1,
        opacity: 0
      })
      .from(".unsold", {
        duration: 1, 
        x: 0,
        ease: "back.out(1.7)",
        yoyo: true,
        scale: 1,
        opacity: 0
      })
      .from(".second", {
        duration: 0.5, 
        x: 60,
        ease: "back.out(1.7)",
        yoyo: true,
        scale: 1,
        opacity: 0
      })
      .from(".third", {
        duration: 0.5, 
        x: 60,
        ease: "back.out(1.7)",
        yoyo: true,
        scale: 1,
        opacity: 0
      })
      .from(".fourth", {
        duration: 0.5, 
        x: 60,
        ease: "back.out(1.7)",
        yoyo: true,
        scale: 1,
        opacity: 0
      })
      .from(".fifth", {
        duration: 0.5, 
        x: 60,
        ease: "back.out(1.7)",
        yoyo: true,
        scale: 1,
        opacity: 0
      })
      .from(".more", {
        duration: 0.5, 
        x: 60,
        ease: "back.out(1.7)",
        yoyo: true,
        scale: 1,
        opacity: 0
      })
      .to(".required-order", {
        duration: 0.5, 
        x: 0,
        ease: "back.out(1.7)",
        yoyo: true,
        scale: 1,
        opacity: 0
      })
      .from(".sold", {
        duration: 0.5, 
        x: 0,
        ease: "back.out(1.7)",
        yoyo: true,
        scale: 1,
        opacity: 0
      })
  
  });


  const ItemWithChevron = ({ header, ...rest }) => (
    <Item
      {...rest}
      header={
        <>
          {header}
          <CaretDown className="chevron-down" size={24} />
        </>
      }
    />
  );

  const AccordionItem = styled(ItemWithChevron)`
    border-bottom: 1px solid #eee;
    .szh-accordion__item {
      &-btn {
        cursor: pointer;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        width: 100%;
        margin: 0;
        padding: 2rem 1.4rem;
        font-size: 1rem;
        font-weight: 400;
        text-align: left;
        color: #111111;
        font-family: 'PolySans', sans-serif;
        font-size: 1.25rem;
        background-color: transparent;
        border: none;
        &:hover {
          background-color: #f3f3f3;
        }
      }

      &-content {
        transition: height 0.2s ease-in-out;
      }

      &-panel {
        padding: 1rem;
      }
    }

    .chevron-down {
      margin-left: auto;
      transition: transform 0.2s ease-in-out;
    }

    &.szh-accordion__item--expanded {
      .szh-accordion__item-btn {
        background-color: #e7e7e7;
      }
      .chevron-down {
        transform: rotate(180deg);
      }
    }
  `;

  const scroll = () => {
    const section = document.querySelector( '#savings' );
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  };

  
  return (
    <div>
      <section className="hero cheapin-hero">
        <div className="hero-body">
          <div className="container is-column">
            <div className="columns">
              <div className="column is-6">
                <h1 className="hero-heading">
                  It’s <span className="hero-underline">pricey</span> when <br /> you’re <span className="hero-underline">alone.</span>
                </h1>
                <p className="subtitle hero-subheading">
                  Start saving now up to 80% by sharing your digital subscription plans or by finding a co-shared plan.
                </p>
                <div className="cta-holder is-flex is-2">
                  <a href="/contact/waitlist" className="button is-large is-black is-rounded is-flex" style={{ gap: '8px' }}>
                    Join the Waiting List <ArrowRight size={24} />
                  </a> 
                  <AnchorLink href="#savings" className="button is-large button is-warning is-light is-rounded is-flex">
                    Calculate Savings &nbsp; <Question size={24} />
                  </AnchorLink> 
                </div>
                <br /> <br />
                <p className="hero-announce">
                  <span className="tag is-link">BETA</span> &nbsp;
                  We're on beta testing with selected users.
                </p>
              </div>
              <div className="column is-6 has-text-centered is-hidden-mobile">
                <div>
                  <div className="owner">
                    <img src={Pao} className="customer" />
                    <p className="from-them" ref={ownerMessage}>Hey! I’m looking to share my Netflix for ₱ 120/month!</p>
                  </div>
                  <img src={HeroThumbnail} className="hero-thumbnail" />
                  {/* <div className="required-order unsold">
                    <h4>50</h4>
                    <small>Required</small>
                  </div>
                  <div className="required-order sold">
                    <div className="count">
                      <h4>Sold!</h4>
                    </div>
                  </div> */}
                  <img src={HeroElement} ref={boxRef} className="hero-element" />
                  <div className="group-buyers">
                    <div className="leader">
                      3 of 4 Slot Available
                      {/* <img src={Paul} className="customer first" />
                      <p className="from-me" ref={chippersRef}>Bought 1, <br /> Chip in na Friends!</p> */}
                    </div>
                    <div className="friends">
                      <img src={Japoy} className="customer second" />
                      <img src={Scott} className="customer third" />
                      <img src={Paul} className="customer fourth" />
                      <div className="more">
                        <h4>1 More!</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="subscriptions" className="py-6 subscription-logos">
        <div className="container has-text-centered	">
          <div className="header pb-4">
            <h3 className="is-size-4">Share any digital subscription:</h3>
          </div>
          <div className="columns is-desktop">
            <div className="column">
              <img src={Netflix} />
            </div>
            <div className="column">
              <img src={DisneyPlus} />
            </div>
            <div className="column">
              <img src={Youtube} />
            </div>
            <div className="column">
              <img src={Spotify} />
            </div>
            <div className="column">
              <img src={Canva} />
            </div>
            <div className="column is-flex is-align-items-center is-justify-content-center">
              <h1 className="is-size-4">
                And more!
              </h1>
            </div>
          </div>
        </div>
      </section>
      <section id="how-it-works" className="hero features">
        <div className="first-element">
          <img src={FirstElement} className="is-hidden-mobile" alt="first element" />
        </div>
        <div className="hero-body">
          <div className="container">
            <div className="columns">
              <div className="column is-12 has-text-centered">
                <h1 className="section-title">
                  How it works.
                </h1>
                {/* <p>We're on a mission to transform independent businesses to accept online orders.</p> */}
              </div>
            </div>
          </div>
          <div className="container">
            <div className="columns is-centered is-vcentered group-page">
              <div className="column is-4 entry">
                <h4 className="title">Share subscription with ease.</h4>
                <p className="description">
                  Select the digital service to share, specify the number of slots and cost, and you're done. Your first sharing group is created.
                </p>
              </div>
              <div className="column is-6 has-text-centered entry">
                <img src={OwnerOne} className="preview" />
                <img src={GroupPageElement} className="element" />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="columns is-centered is-vcentered accept-payments reverse-columns">
              <div className="column is-6 has-text-centered entry">
                <img src={OwnerTwo} className="preview" />
                <img src={PaymentCheckoutElement} className="element" />
              </div>
              <div className="column is-5 entry">
                <h5 className="title">Allow incoming members.</h5>
                <p className="description">
                  Pick the digital service to share, enter the number of open slots and the price, and you're set. Your initial sharing group is established.
                </p>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="columns is-centered is-vcentered set-rules">
              <div className="column is-4 entry">
                <h5 className="title">Effortlessly gather payments.</h5>
                <p className="description">
                  We handle collecting funds from all members on a predetermined schedule, which you can then transfer to your bank account at no cost.
                </p>
              </div>
              <div className="column is-6 has-text-centered entry">
                <img src={OwnerThree} className="preview" />
                <img src={RulesElement} className="element" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="savings" className="savings p-4">
        <div className="container is-max-desktop">
          <div class="card is-full-width has-background-dark-blue p-4">
            <div class="card-content has-text-centered">
              <h1 className="is-size-2 has-text-white section-title">
                Calculate Monthly Savings
              </h1>
              <div class="content has-text-white mt-1 section-subheading">
                See how much you can save by sharing your digital subscriptions with others.
              </div>

              <div className="columns is-flex is-align-items-center my-4 calculator">
                <div className="column is-flex is-fullwidth is-flex-direction-column is-align-items-center is-justify-content-center" style={{ width: '100%'}}>
                  <div className="computation-text">
                    <h1 className="is-size-2 has-text-white">
                      ₱ <span>{subscriptionAmount}</span>
                    </h1>
                    <p className="has-text-white pt-5">What is your monthly subscription cost?</p>
                  </div>
                  <InputRange
                    maxValue={1500}
                    minValue={0}
                    step={100}
                    value={subscriptionAmount}
                    onChange={handleSubscriptionAmountChange}
                    classNames={defaultClassNames} 
                  />
                </div>
                <div className="column is-flex is-fullwidth is-flex-direction-column is-align-items-center is-justify-content-center"  style={{ width: '100%'}}>
                  <div className="computation-text">
                    <h1 className="is-size-2 has-text-white">
                      {numberOfPeople}
                    </h1>
                    <p className="has-text-white pt-5">How many slots shared to others?</p>
                  </div>
                  <InputRange
                    maxValue={4}
                    minValue={1}
                    step={1}
                    value={numberOfPeople}
                    onChange={handleNumberOfPeopleChange}
                    classNames={defaultClassNames} 
                  />
                </div>
                <div className="column is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
                  <h3 className="is-size-4 has-text-white">You save</h3>
                  <h1 className="is-size-2 has-text-white">
                    ₱ {monthlySavings}/mo.
                  </h1>
                  <p style={{ marginTop: '8px', color: '#ffcc00', fontSize: '18px' }}>You pay <strong style={{color: '#ffcc00' }}>₱{computeSavings} per month</strong> now.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="faq" className="hero faq">
        <div className="hero-body">
          <div className="container">
            <div className="columns">
              <div className="column is-12 has-text-centered">
                <h1 className="section-title">
                FAQs
                </h1>
              </div>
            </div>
          </div>
          <div className="container is-max-desktop">
            <div className="columns">
              <div className="column is-12 entry accords">
                <Accordion>
                  <AccordionItem header="What is Cheap In?">
                    Cheap In is a platform that allows users to split the cost of digital products and services, resulting in savings of up to 80%. By sharing the expense with your family, friends, roommates, or coworkers, users can significantly reduce the amount you pay for digital products and services.
                  </AccordionItem>

                  <AccordionItem header="How does it work?">
                    Cheap In can be utilized in two capacities, as a Host or as a Joiner. When acting as a Host, you have the option to share your multi-user/family subscription by forming a sharing group. If you prefer to join a group created by someone else, you can do so as a Joiner, as long as you adhere to the established terms of the group.
                  </AccordionItem>

                  <AccordionItem header="Is it legal to share a subscription?">
                    It is generally legal to share a subscription, as many product or service providers offer multi-user plans that can be shared among multiple users as long as the terms and conditions of the service are followed. Cheap In is a platform that helps manage payments for these sharing groups, making the process more convenient and secure for all parties involved.     
                    <br /> <br />Please note that the specifics of whether sharing a subscription is legal may vary depending on the terms and conditions of the specific subscription service in question.
                  </AccordionItem>

                  <AccordionItem header="Why Cheap In?">
                    Cheap In is a platform that aims to make sharing digital subscriptions easy and hassle-free. Many people struggle with asking for payment for shared subscriptions or with paying for expensive subscriptions on their own. Cheap In offers a safe and seamless solution for these problems. If you want to share subscriptions without constantly reminding your group members to pay, or if you want to afford a subscription without breaking the bank, Cheap In is the place for you.
                  </AccordionItem>

                  <AccordionItem header="I'm interested, how can I reach out to Cheap In?">
                    You can reach out to us anytime at hello@cheapin.ph
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`
